import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ActionWrapper, OuterContainer } from "../style";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import {
  getRequestsApi,
  updateRequestApi,
} from "../../services/Api/collection";
import { Button, Image, Tooltip, message } from "antd";
import { useLocation } from "react-router-dom";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import { errorMessage } from "../../utils/commonMethod";
import moment from "moment";

const limit = 10;
export default function Requests() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [openModal, setopenModal] = useState(false);
  const [record, setrecord] = useState();
  const [cancleModal, setCancleModal] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const onPageChange = (page) => setCurrentPage(page);

  const getNewRequestList = async () => {
    setLoading(true);
    let project_id;
    if(params.has("project_id")) {
      project_id = params.get("project_id");
    }
    else{
      project_id ="All"
    }
    const res = await getRequestsApi(currentPage, limit, project_id);
    if (res?.status === 200) {
      const { Requests = [], TotalCount } = res?.data || {};
      setData(Requests);
      setTotalCount(TotalCount);
    }
    setLoading(false);
  };
  const handleDrawerOpenClose = () => {
    setopenModal((pre) => !pre);
  };

  useEffect(() => {
    getNewRequestList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const openAcceptConfirmModal = (userdetail) => {
    setUserInfo(userdetail);
    closeOpenAcceptModal();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "name",
      render: (_, record) => <div>{record?.project_name}</div>,
    },
    {
      title: "Status",
      dataIndex: "project_status",
      key: "status",
      render: (_, record) => <div>{record?.status}</div>,
    },
    {
      title: "Create Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (_, record) => <div>{moment(record?.start_date).format('MMM D, YYYY')}</div>,
    },
    {
      title: "Project Details",
      dataIndex: "project_name",
      key: "project_name",
      render: (_, dataIndex) => (
        <Userbuttons>
          <Button
            style={{ cursor: "pointer" }}
            onClick={() => {
              setopenModal(true);
              setrecord(dataIndex);
            }}
          >
            {" "}
            View Details
          </Button>
          <a
            //PROD/DEV/LOCAL SWITCH
            href={`https://us02.procore.com/562949953425469/company/directory/users/${dataIndex.user_id}`} //prod
            // href={`https://sandbox.procore.com/4271567/company/directory/users/${dataIndex.user_id}`} //dev
            target="_blank"
            rel="noreferrer"
          >
            <Button>User Details</Button>
          </a>
        </Userbuttons>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <ActionWrapper>
          <Tooltip
            title="Accept Request"
            onClick={() => openAcceptConfirmModal(record)}
          >
            <StatusButton name="accept">Accept</StatusButton>
          </Tooltip>
          <Tooltip
            title="Cancel Request"
            onClick={() => openCancelConfirmModal(record)}
          >
            <StatusButton name="delete">Delete</StatusButton>
          </Tooltip>
        </ActionWrapper>
      ),
    },
  ];

  const openCancelConfirmModal = (userDetail) => {
    setUserInfo(userDetail);
    closeOpenCancelModal();
  };

  const closeOpenAcceptModal = () => setOpenAcceptModal((pre) => !pre);

  const closeOpenCancelModal = () => setCancleModal((pre) => !pre);

  const handleAcceptConfirm = async () => {
    const res = await updateRequestApi({
      isAccept: "2",
      projectRequestId: userInfo?._id,
      status: "InProgress",
    });
    if (res?.status === 200) {
      message.success(res?.message);
      closeOpenAcceptModal();
      currentPage === 1 ? getNewRequestList() : setCurrentPage(1);
    } else errorMessage(res);
  };

  const handleDeleteConfirm = async () => {
    const res = await updateRequestApi({
      isAccept: "3",
      projectRequestId: userInfo?._id,
      status: "Rejected",
    });
    if (res?.status === 200) {
      message.success(res?.message);
      closeOpenCancelModal();
      currentPage === 1 ? getNewRequestList() : setCurrentPage(1);
    } else errorMessage(res);
  };

  return (
    <RequestWrapper>
      {/* <div className="heading-section">New Requests</div>
      <div>
        <span className="back-button" onClick={backNavigate}>
          Back
        </span>
      </div> */}
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      {openAcceptModal && (
        <ModalComponent
          openModal={openAcceptModal}
          setOpenModal={closeOpenAcceptModal}
        >
          <ConfirmModal
            handleConfirm={handleAcceptConfirm}
            subheading="You want to accept the request."
            handleCancel={closeOpenAcceptModal}
            confirmBtnText="Accept"
          />
        </ModalComponent>
      )}
      <ModalComponent
        openModal={openModal}
        setOpenModal={handleDrawerOpenClose}
      >
        <Modaldetails>
          <li>
            <spna className="highliter">Emergency Images </spna> :
            {record?.photos.length ? (
              <>
                <br></br>
                {record?.photos.map((e) => (
                  <Image
                    style={{
                      width: "100px",
                      height: "100px",
                      padding: "5px",
                    }}
                    alt=""
                    src={`https://de7nc1t6mbc89.cloudfront.net/${e}`}
                  />
                ))}
              </>
            ) : (
              "N/A"
            )}
          </li>
          <li>
            {" "}
            <span className="highliter">Address : </span>{" "}
            {record?.address ?? "N/A"}
          </li>
          <li> <span className="highliter">Status :  </span>{record?.status ?? "   N/A"}</li>
          <li> <span className="highliter">Start Date : </span> {record?.start_date ?? "N/A"}</li>
          <li> <span className="highliter">Start Time : </span> {record?.start_time ?? "N/A"}</li>
          <li > <span className="highliter">End Date : </span> {record?.end_date ?? "N/A"}</li>
          <li> <span className="highliter"> End Time :</span> {record?.end_time ?? "N/A"}</li>
          <li>
            {" "}
            <span className="highliter">City : </span> {record?.city ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter"> comment : </span>{" "}
            {record?.comment.length ? record?.comment : "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">country_code : </span>{" "}
            {record?.country_code ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">county : </span>{" "}
            {record?.county ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">latitude : </span>{" "}
            {record?.latitude ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">longitude : </span>{" "}
            {record?.longitude ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">status : </span>{" "}
            {record?.status ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">state_code : </span>{" "}
            {record?.state_code ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">zip : </span>
            {record?.zip ?? "N/A"}
          </li>
          <li>
            <span className="highliter">Equipment Required : </span>
            {record?.equipment?.map((e) => (
              <>
                {" "}
                {e?.equipment_name}({e?.equipment_size}),{" "}
              </>
            ))}
          </li>
          <li>
            {" "}
            <span className="highliter">labour Required : </span>
            {record?.labor_size ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">labour type : </span>
            {record?.labor_type === 0
              ? "general"
              : record?.labor_type === 1
              ? "skilled"
              : ""}
          </li>
        </Modaldetails>
      </ModalComponent>
      {cancleModal && (
        <ModalComponent
          openModal={cancleModal}
          setOpenModal={closeOpenCancelModal}
        >
          <ConfirmModal
            subheading="You want to Delete the request."
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenCancelModal}
          />
        </ModalComponent>
      )}
    </RequestWrapper>
  );
}

const RequestWrapper = styled(OuterContainer)`
  .back-button {
    display: flex;
    color: grey;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
  }
`;

const StatusButton = styled.div`
  background: ${(props) => (props.name === "accept" ? "green" : "red")};
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
`;

const Modaldetails = styled.div`
  li {
    font-size: 14px;
    padding: 2px;
    .highliter {
      font-weight: bold;
    }
  }
`;
const Userbuttons = styled.div`
display:flex;
justify-content: space-around;

`
