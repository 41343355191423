import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ActionWrapper, OuterContainer } from "../style";
import SearchField from "../../components/fields/searchField";
// import ButtonComponent from "../../components/fields/button";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import { Button, Image, Tooltip, message } from "antd";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import ViewDetails from "./viewInfo";
import DrawerComponent from "../../components/drawer";
import ProjectForm from "./form";
import {
  deleteProjectApi,
  getcompletetask,
  getinprogrsstaskapi,
  getsearchdata,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import { useLocation } from "react-router-dom";
import moment from "moment";

const limit = 10;
export const ProjectManagement = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordview, setRecordView] = useState();
  const [record, setrecord] = useState();
  const [opendeatilsmodal, setopendetailsmodal] = useState(false);

  const handleSearch = (searchText) => setSearchText(searchText);

  const onPageChange = (page) => setCurrentPage(page);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const getProjectLists = async () => {
    setLoading(true);
    let project_id;
    if(params.has("project_id")) {
      project_id = params.get("project_id");
    }
    else{
      project_id ="All"
    }
    

    const res = await getinprogrsstaskapi(currentPage, limit,project_id);
    if (res?.status === 200) {
      setData(res?.data?.Requests);
      setTotalCount(res?.data?.TotalCount);
    } else errorMessage(res);
    setLoading(false);
  };


  const handleDrawerOpenClose = (e) => {
    setViewModal((pre) => !pre);
    setRecordView(e);
  };

  const openDeleteConfirmModal = (record) => {
    setUserInfo(record);
    setOpenDeleteModal((pre) => !pre);
  };

  const handleSearchData = async () => {
    setLoading(true);
    const isAccept = 2;
    const res = await getsearchdata(currentPage, limit, searchText, isAccept);
    if (res?.status === 200) {
      setTotalCount(res?.data?.TotalCount);
      setData(res?.data?.finalRequestedData);
    } else errorMessage(res);
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) handleSearchData();
    else getProjectLists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  const closeOpenDeleteModal = () => setOpenDeleteModal((pre) => !pre);

  const closeOpenViewModal = () => setViewModal((pre) => !pre);

  const handleDeleteConfirm = async () => {
    const res = await deleteProjectApi(userInfo?._id);
    if (res?.status === 200) {
      message.success(res?.message);
      currentPage === 1 ? getProjectLists() : setCurrentPage(1);
    } else errorMessage(res);
    setOpenDeleteModal((pre) => !pre);
  };

  const handleAddInfo = (selected_project) => {
    setUserInfo(selected_project);
    setOpenDrawer(true);
  };

  const handleOpenCloseDrawer = () => setOpenDrawer((pre) => !pre);
  const handlecompletetask = async (id) => {
    console.log(id, "id");
    const req = {
      requestId: id?._id,
    };
    const res = await getcompletetask(req);
    if (res?.status === 200) {
      message.success("successfully completed project");
      getProjectLists();
    } else {
      errorMessage(req);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "name",
    },

    {
      title: "Create Date",
      dataIndex: "start_date",
      key: "start_date",
      render:(_,data)=>(
        <p>{moment(data.start_date).format('MMM D, YYYY')}</p> 
     )
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => (
        <>
          {record?.city},{record?.county}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <ActionWrapper>
          <Tooltip
            title="delete Project"
            onClick={() => openDeleteConfirmModal(record)}
          >
            <DeleteOutlined />
          </Tooltip>
          {record?.status === "InProgress" ? (
            <>
              {" "}
              <Tooltip
                title="view Project detail"
                onClick={() => handleDrawerOpenClose(record)}
              >
                <EyeOutlined />
              </Tooltip>
            </>
          ) : (
            <></>
          )}

          {record?.status === "InProgress" ? (
            <>
              {" "}
              <Tooltip
                title="assign team/vehicle"
                onClick={() => handleAddInfo(record)}
              >
                <PlusOutlined />
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </ActionWrapper>
      ),
    },
    {
      title: "Status",
      dataIndex: "project_status",
      key: "status",
      render: (data, record) => {
        if (record?.status === "InProgress") {
          return (
            <Buttonstatus style={{ background: "#f5bc05" }}>
              InProgress
            </Buttonstatus>
          );
        } else if (record?.status === "Completed") {
          return <Buttonstatus>Completed</Buttonstatus>;
        } else {
          return (
            <Buttonstatus style={{ background: "red" }}>Rejected</Buttonstatus>
          );
        }
      },
    },
    {
      title: "completed",
      key: "completed",
      render: (_, record) => (
        <>
          {record?.status === "InProgress" ? (
            <>
              {" "}
              <Buttonstatus onClick={() => handlecompletetask(record)}>
                Click to Complete
              </Buttonstatus>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Details",
      key: "details",
      render: (_, dataIndex) => (
        <Userbuttons>
          <Button
            onClick={() => {
              setopendetailsmodal(true);
              setrecord(dataIndex);
            }}
          >
            View Details
          </Button>
          <a
            //PROD/DEV/LOCAL SWITCH
            href={`https://us02.procore.com/562949953425469/company/directory/users/${dataIndex.user_id}`} //prod
            // href={`https://sandbox.procore.com/4271567/company/directory/users/${dataIndex.user_id}`} //dev
            target="_blank"
            rel="noreferrer"
          >
            <Button>User Details</Button>
          </a>
        </Userbuttons>
      ),
    },
  ];

  return (
    <ProjectWrapper>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
      </div>

      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      {openDrawer && (
        <DrawerComponent
          open={openDrawer}
          handleClose={handleOpenCloseDrawer}
          title="Add Team/Vehicle"
        >
          <ProjectForm
            projectInfo={userInfo}
            handleClose={handleOpenCloseDrawer}
            update={getProjectLists}
          />
        </DrawerComponent>
      )}
      {openDeleteModal && (
        <ModalComponent
          openModal={openDeleteModal}
          setOpenModal={closeOpenDeleteModal}
        >
          <ConfirmModal
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenDeleteModal}
          />
        </ModalComponent>
      )}
      <ModalComponent
        openModal={opendeatilsmodal}
        setOpenModal={() => setopendetailsmodal(false)}
      >
        <Modaldetails>
          <li>
            <spna className="highliter">Emergency Images </spna> :
            {record?.photos.length ? (
              <>
                <br></br>
                {record?.photos.map((e) => (
                  <Image
                    style={{
                      width: "100px",
                      height: "100px",
                      padding: "5px",
                    }}
                    alt=""
                    src={`https://de7nc1t6mbc89.cloudfront.net/${e}`}
                  />
                ))}
              </>
            ) : (
              "N/A"
            )}
          </li>
          <li>
            {" "}
            <span className="highliter">Address : </span>{" "}
            {record?.address ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Status : </span>
            {record?.status ?? "   N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Start Date : </span>{" "}
            {record?.start_date ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Start Time : </span>{" "}
            {record?.start_time ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">End Date : </span>{" "}
            
            {record?.updatedAt.split("T",1) ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter"> End Time :</span>{" "}
            {record?.updatedAt.split('')?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">City : </span> {record?.city ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter"> comment : </span>{" "}
            {record?.comment.length ? record?.comment : "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Status : </span>
            {record?.status ?? "   N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Start Date : </span>{" "}
            {record?.start_date ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">Start Time : </span>{" "}
            {record?.start_time ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">End Date : </span>{" "}
            {record?.end_date ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter"> End Time :</span>{" "}
            {record?.end_time ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">country_code : </span>{" "}
            {record?.country_code ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">county : </span>{" "}
            {record?.county ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">latitude : </span>{" "}
            {record?.latitude ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">longitude : </span>{" "}
            {record?.longitude ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">status : </span>{" "}
            {record?.status ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">state_code : </span>{" "}
            {record?.state_code ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">zip : </span>
            {record?.zip ?? "N/A"}
          </li>
          <li>
            <span className="highliter">Equipment Required : </span>
            {record?.equipment?.map((e) => (
              <>
                {" "}
                {e?.equipment_name}({e?.equipment_size}),{" "}
              </>
            ))}
          </li>
          <li>
            {" "}
            <span className="highliter">labour Required : </span>
            {record?.labor_size ?? "N/A"}
          </li>
          <li>
            {" "}
            <span className="highliter">labour type : </span>
            {record?.labor_type === 0
              ? "general"
              : record?.labor_type === 1
              ? "skilled"
              : ""}
          </li>
        </Modaldetails>
      </ModalComponent>
      {viewModal && (
        <ModalComponent openModal={viewModal} setOpenModal={closeOpenViewModal}>
          <ViewDetails vehicle={recordview} />
        </ModalComponent>
      )}
    </ProjectWrapper>
  );
};

const ProjectWrapper = styled(OuterContainer)`
  .filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .new-request {
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
  .request-link {
    display: flex;
    justify-content: right;
  }
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
  }
  .search-wrapper {
    flex: 1;
  }
`;
const Buttonstatus = styled.button`
  background: green;
  font-size: 14px;
  border: none;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
`;
const Modaldetails = styled.div`
  li {
    font-size: 14px;
    padding: 2px;
    .highliter {
      font-weight: bold;
    }
  }
`;
const Userbuttons = styled.div`
display:flex;
justify-content: space-around;
gap:5px;

`
